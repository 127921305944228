.Contact-container {
  width: 100%;
  background: #f4f6fc;
  padding: 40px 0;
}
.contact {
  max-width: 1200px;
  display: flex;
  margin: 0 auto;
  width: 95%;
  flex-direction: column-reverse;
  padding-top: 30px;
}
.contact-form h3 {
  font-family: "Inter";
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  color: #080055;
  padding-bottom: 30px;
}
.con-contact-form {
  /* width: 60%; */
  padding: 20px;
}
.contact-form {
  background: #ffffff;
  padding: 20px 20px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
}
.form-field {
  display: flex;
  justify-content: space-between;
}
.inner-field {
  width: 45%;
  margin-bottom: 19px;
  display: flex;
  flex-direction: column;
}
.inner-field2 {
  width: 99%;
  margin-bottom: 19px;
  display: flex;
  flex-direction: column;
}
.inner-field22 {
  display: none;
}

.inner-field input,
.inner-field2 input {
  padding: 10px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 3px;
}
.inner-field ::placeholder,
.inner-field2 ::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #c0c0c2;
}
.label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1c1939;
  padding-bottom: 7px;
}
.message {
  display: flex;
  flex-direction: column;
}
.message textarea {
  min-height: 120px;
  padding: 20px 20px 0px 40px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 3px;
  margin-bottom: 20px;
}
.contact-form button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 35px;
  gap: 10px;
  border: none;
  background: #377dff;
  border-radius: 6px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.contact-picture {
}
.contact-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 700px) {
  .Contact-container {
    padding: 0px 0;
  }
  .contact {
    padding-top: 0px;
  }
  .contact-picture {
    display: none;
  }

  .label {
    font-size: 16px;
  }
  .form-field {
    display: inline-block;
  }
  .inner-field {
    display: none;
  }
  .inner-field22 {
    display: block;
    width: 99%;
    margin-bottom: 19px;
    display: flex;
    flex-direction: column;
  }
  .inner-field22 input {
    padding: 10px;
    background: #ffffff;
    border: 1px solid #e4e4e4;
    border-radius: 3px;
  }
  .inner-field22 ::placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #c0c0c2;
  }
  .contact-form h3 {
    padding-bottom: 0px;
  }
}

@media screen and (min-width: 701px) and (max-width: 900px) {
  .inner-field ::placeholder,
  .inner-field2 ::placeholder {
    font-size: 19px;
  }
  .label {
    font-size: 17px;
  }
  .contact-picture {
    display: none;
  }
  .Contact-container {
    padding: 0px 0;
  }
}
@media screen and (min-width: 901px) and (max-width: 900px) {
  * {
    overflow-x: hidden;
  }
  .inner-field ::placeholder,
  .inner-field2 ::placeholder {
    font-size: 19px;
  }
  .label {
    font-size: 17px;
  }
}
@media (min-width: 992px) {
  .Contact-container {
    padding: 0;
  }

  .contact {
    flex-direction: row;
  }
}
