.value_container {
  width: 100%;
  background: #f4f6fc;
  padding: 70px 0px 60px 0px;
}
.value_paragraph1 {
  text-align: center;
  margin: auto;
  font-family: "Inter";
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
  color: #172b4d;
}
.values {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 75px 0px 10px 0px;
}
.value_button {
  width: 45px;
  height: 45px;
  border-radius: 5px;
  border: none;
}
.container {
  width: 21%;
}
.font {
  font-size: 26px;
  color: #ffff;
}
.security {
  background: #3861fb;
}
.transparency {
  background: #108953;
}
.efficiency {
  background: #774fda;
}
.innovation {
  background: #fdbd47;
}
.p1 {
  font-size: 23px;
  font-family: "inter";
  font-weight: 800;
  line-height: 30px;
  text-align: left;
  color: #172b4d;
  padding-top: 30px;
}
.p2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 27px;
  color: #5b5971;
}
@media only screen and (max-width: 480px) and (min-width: 320px) {
  .value_container {
    padding: 50px 0px 60px 0px;
  }
  .value_paragraph1 {
    font-size: 25px;
    font-weight: 800;
    line-height: 40px;
  }
  .values {
    width: 100%;
    margin: auto;
    flex-direction: column;
    padding: 55px 0px 10px 0px;
  }

  .container {
    width: 70%;
    margin: auto;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .font {
    font-size: 26px;
  }
  .p1 {
    font-size: 21px;
    font-weight: 700;
    line-height: 30px;
    padding-top: 10px;
  }
  .p2 {
    font-size: 18px;
    line-height: 27px;
    padding-bottom: 30px;
  }
}
@media only screen and (max-width: 900px) and (min-width: 481px) {
  .values {
    width: 100%;
    margin: auto;
    flex-direction: column;
    padding: 55px 0px 10px 0px;
  }
  .container {
    width: 70%;
    margin: auto;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .p1 {
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    padding-top: 15px;
  }
  .p2 {
    font-size: 20px;
    line-height: 27px;
    padding-bottom: 30px;
  }
}
