.heroSession {
  width: 100;
}
.heroCont {
  width: 100%;
  padding: 60px 70px 30px 70px;
  display: flex;
  margin: auto;
  justify-content: space-between;
}
.writeup {
  padding-top: 50px;
  width: 54%;
}
.imageCont {
  width: 37%;
  display: flex;
  justify-content: flex-end;
}
.imageCont img {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.paragraph1 {
  font-family: "Inter";
  font-size: 49px;
  font-weight: 900;
  line-height: 59px;
  text-align: left;
  padding-top: 40px;
  color: #172b4d;
}
.paragraph2 {
  font-family: "Inter";
  padding-top: 30px;
  font-size: 24px;
  line-height: 34px;
  color: #5b5971;
}
@media only screen and (max-width: 480px) and (min-width: 320px) {
  .heroCont {
    flex-direction: column;
    padding: 20px 20px 30px 20px;
    text-align: center;
  }
  .writeup {
    width: 100%;
    padding-top: 30px;
  }
  .imageCont {
    width: 100%;
    justify-content: flex-start;
    margin: auto;
  }
  .imageCont img {
    width: 90%;
    margin: auto;
  }
  .paragraph1 {
    font-size: 25px;
    font-weight: 800;
    line-height: 30px;
    padding-top: 0px;
    text-align: center;
  }
  .paragraph2 {
    padding-top: 20px;
    font-size: 17px;
    line-height: 27px;
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 900px) and (min-width: 481px) {
  .heroCont {
    flex-direction: column;
    padding: 40px 30px 30px 30px;
    text-align: center;
  }
  .writeup {
    width: 100%;
  }
  .imageCont {
    width: 100%;
    justify-content: flex-start;
    margin: auto;
  }
  .imageCont img {
    width: 90%;
    margin: auto;
  }
  .paragraph1 {
    font-size: 34px;
    font-weight: 800;
    line-height: 35px;
    padding-top: 0px;
    text-align: center;
  }
  .paragraph2 {
    padding-top: 20px;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 50px;
  }
}
