.p-container {
  max-width: 540px;
  padding: 15px;
  margin: 20px auto 50px;
  width: 100%;
}
.text-center {
	text-align: center;
}
h4.pp{
  margin: 15px 5px 5px 10px !important;
}
p.pp {
	text-align: justify;
	margin-top: 10px;
}
ul.list li {
	list-style: square !important;
	padding: 10px 10px 0px 10px;
	margin-left: 10px;
}
ul.sub-list li {
	list-style: disclosure-closed !important;
	padding: 10px 5px 0px 10px;
	margin-left: 30px;
}
ol li {
  list-style: lower-roman;
	padding: 10px 10px 0px 10px;
	margin-left: 10px;
}
@media only screen and (min-width: 576px) {
  h4{
    margin: 15px 5px 5px 20px;
  }
	.p-container {
		max-width: 540px;
	}
}

@media only screen and (min-width: 768px) {
  h4{
    margin: 15px 5px 5px 30px;
  }
	.p-container {
		max-width: 720px;
	}
}

@media only screen and (min-width: 992px) {
  h4{
    margin: 15px 5px 5px 40px;
  }
	.p-container {
		max-width: 960px;
	}
}
@media only screen and (min-width: 1200px) {
  h4{
    margin: 15px 5px 5px 50px;
  }
	.p-container {
		max-width: 1140px;
	}
}
